import { LANG } from '@/language'

export const unit = {
  hectare: 'ha',
  acre: 'ac',
  'kg/ha': 'kg/ha',
  'bu/ac': 'bu/ac',
  'sc/ha': 'sc/ha',
  meter: 'm',
  centimeter: 'cm',
  millimeter: 'mm',
  inch: 'in',
  feet: 'ft',
  saca: 'sc',
  bushel: 'bu',
  kilogram: 'kg',
  'km/h': 'km/h',
  mph: 'mph',
  celsius: 'ºC',
  fahrenheit: 'ºF',
  liter: 'L',
  gallon: 'gal',
}

export const unitMeasure = {
  area: {
    [LANG.PT]: unit.hectare,
    [LANG.ES]: unit.hectare,
    [LANG.EN]: unit.acre,
  },
  density: {
    [LANG.PT]: unit['kg/ha'],
    [LANG.ES]: unit['kg/ha'],
    [LANG.EN]: unit['bu/ac'],
  },
  performance: {
    [LANG.PT]: unit['sc/ha'],
    [LANG.ES]: unit['sc/ha'],
    [LANG.EN]: unit['bu/ac'],
  },
  rainfall: {
    [LANG.PT]: unit.millimeter,
    [LANG.ES]: unit.millimeter,
    [LANG.EN]: unit.inch,
  },
  length_in: {
    [LANG.PT]: unit.centimeter,
    [LANG.ES]: unit.centimeter,
    [LANG.EN]: unit.inch,
  },
  length_ft: {
    [LANG.PT]: unit.meter,
    [LANG.ES]: unit.meter,
    [LANG.EN]: unit.feet,
  },
  weight_kg: {
    [LANG.PT]: unit.kilogram,
    [LANG.ES]: unit.kilogram,
    [LANG.EN]: unit.bushel,
  },
  weight: {
    [LANG.PT]: unit.saca,
    [LANG.ES]: unit.saca,
    [LANG.EN]: unit.bushel,
  },
  speed: {
    [LANG.PT]: unit['km/h'],
    [LANG.ES]: unit['km/h'],
    [LANG.EN]: unit.mph,
  },
  temperature: {
    [LANG.PT]: unit.celsius,
    [LANG.ES]: unit.celsius,
    [LANG.EN]: unit.fahrenheit,
  },
  volume: {
    [LANG.PT]: unit.liter,
    [LANG.ES]: unit.liter,
    [LANG.EN]: unit.gallon,
  },
}

export const unitConversions = {
  /* Ref: https://www.ers.usda.gov/webdocs/publications/41880/33132_ah697_002.pdf */
  [unit.hectare]: {
    [unit.acre]: 2.471044 /* Page 6 */,
  },
  [unit.kilogram]: {
    [unit.bushel]: 0.036744 /* 1kg = 0.036744bu (Wheat and Soybeans) - Page 10 */,
  },
  [unit.saca]: {
    [unit.bushel]: 2.20464 /* 1 saca = 60kg */,
  },
  [unit['sc/ha']]: {
    [unit['bu/ac']]: 0.89219,
  },
  [unit['kg/ha']]: {
    [unit['bu/ac']]: 0.01487,
  },
  [unit.millimeter]: {
    [unit.inch]: 0.03937 /* Page 9 */,
  },
  [unit.centimeter]: {
    [unit.inch]: 0.3937 /* Page 6 */,
  },
  [unit.meter]: {
    [unit.feet]: 3.28084 /* Page 6 */,
  },
  [unit['km/h']]: {
    [unit.mph]: 0.621 /* Page 9 */,
  },
  [unit.celsius]: {
    [unit.fahrenheit]: 1.8 /* Tf = (Tc * 9/5) + 32 *(32 value is added directely into converter function) */,
  },
  [unit.liter]: {
    [unit.gallon]: 0.264172 /* Page 8 */,
  },
}
