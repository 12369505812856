export default {
  Menu: {
    alterar_conta: 'Alterar Conta',
    trocar_senha: 'Alterar Senha',
    gerenciar_usuarios: 'Gerenciar Usuários',
    indique_ganhe: 'Indique e ganhe',
    about_the_user: 'Seu usuário',
    alterar_idioma: 'Alterar Idioma',
    config_area: 'Configurar área',
  },
}
