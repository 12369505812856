import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './messages'
import numberFormats from './numbers'
import dateTimeFormats from './date'

import { localeOptions } from './locales'

Vue.use(VueI18n)

export const LANG = {
  PT: 'pt',
  EN: 'en',
  ES: 'es',
}

const i18n = new VueI18n({
  locale: LANG.PT,
  messages,
  numberFormats,
  dateTimeFormats,
})

export const currencyFormatter = (value) => {
  return i18n.n(value, 'currency')
}

export const formatNumber = (value) => {
  return currencyFormatter(value)
    .replaceAll('US$|BRL|R$$|$', '')
    .replace(' ', '')
}

export const formatDecimal = (value, precision) => {
  if (precision) {
    return i18n.n(value, 'decimal', {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    })
  }
  return i18n.n(value, 'decimal')
}

export const currentCurrency = () => {
  return i18n.messages[i18n.locale].moeda
}

export const currentLanguage = () => {
  return i18n.locale
}

export const defaultLanguage = () => {
  return localeOptions.find((language) => language.value === 'en').value
}

export const getBrowserLanguage = () => {
  const storageLanguage = localStorage.getItem('language')
  if (storageLanguage) return storageLanguage

  const browserLanguage = navigator.language.split('-')[0]
  const checkIfLanguageEnable = Object.values(LANG).includes(browserLanguage)

  if (checkIfLanguageEnable) {
    const lang = localeOptions.find(
      (language) => language.value === browserLanguage
    ).value
    localStorage.setItem('language', lang)
    return lang
  }

  const defaultLang = defaultLanguage()
  localStorage.setItem('language', defaultLang)

  return defaultLang
}

export const currentLocale = () => {
  return dateTimeFormats[currentLanguage()].locale
}

export const formatDate = (date) => {
  return i18n.d(date, 'short')
}

i18n.locale = getBrowserLanguage()

export default i18n
