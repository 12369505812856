export default {
  phytosanitary_category: {
    diseases: 'Doenças',
    insects: 'Insetos',
    nematodes: 'Nematóides',
    weeds: 'Ervas Daninhas',
  },
  genetics_category: {
    variety: 'Cultivar',
    hybrids: 'Híbrido',
    varieties: 'Variedade',
  },
  plant_nutrition_category: {
    correction_k: 'Correção potássio',
    correction_p: 'Correção fósforo',
    correction_ph: 'Correção pH',
    cover_crops: 'Adubação orgânica através culturas de cobertura',
    manutention_k: 'Manutenção K',
    manutention_n: 'Manutenção N',
    manutention_p: 'Manutenção P',
    organic_manure: 'Adubação orgânica com esterco',
    plant_nutrition: 'Nutrição de plantas',
    resistance_activation: 'Ativação de resistência',
    sowing_k: 'Aplicação de K na Semeadura',
    sowing_n: 'Aplicação de N na Semeadura',
    sowing_p: 'Aplicação de P na Semeadura',
    yield_activation: 'Ativação de produtividade',
  },
  operation_type: {
    aerial_spray: 'Aplicação na Parte Aérea',
    correction_fertilization: 'Adubação de Correção',
    desiccation_pre_harvest: 'Dessecação Pré-Colheita',
    early_emerging_post: 'Pós-Emergente Inicial',
    foliar_fertilization: 'Adubação Foliar',
    harvest: 'Colheita',
    in_furrow: 'Aplicação no Sulco de Semeadura',
    late_emerging_post: 'Pós-Emergente Tardio',
    maintenance_fertilization: 'Adubação de Manutenção',
    organic_fertilizer: 'Fertilizante Orgânico',
    pre_emerging: 'Pré-Emergente',
    pre_emergent_overlay: 'Sobreposição Pré-Emergente',
    regular_emerging_post: 'Pós Emergente Regular',
    seed_treatment: 'Tratamento de Sementes',
    sequential_desiccation_1: 'Dessecação Sequencial 1',
    sequential_desiccation_2: 'Dessecação Sequencial 2',
    sowing: 'Semeadura',
    spray_on_bare_ground: 'Aplicação em Solo Descoberto',
  },
  crop: {
    fallow: 'Pousio',
    others: 'Outras',
    alfalfa: 'Alfala',
    barley: 'Cevada',
    bean: 'Feijão',
    black_oats: 'Aveia Preta',
    brachiaria: 'Braquiária',
    brachiaria_bizantha: 'Braquiária-Bizantha',
    brachiaria_decumbens: 'Braquiária-Decumbens',
    brachiaria_riziziensis: 'Braquiária-Riziziensis',
    canola: 'Canola',
    corn: 'Milho',
    cotton: 'Algodão',
    cowpea: 'Feijão-Caupi',
    crotalaria: 'Crotalária',
    crotalaria_breviflora: 'Crotalária-breviflora',
    crotalaria_juncea: 'Crotalária-júncea',
    crotalaria_ochroleuca: 'Crotalária-ochroleuca',
    crotalaria_spectabilis: 'Crotalária-spectabilis',
    dry_pea: 'Ervilha Seca',
    flax: 'Linho',
    forage_pea: 'Ervilha Forrageira',
    forage_sorghum: 'Sorgo Forrageiro',
    grain_sorghum: 'Sorgo Granífero',
    irrigated_rice: 'Arroz Irrigado',
    lupin: 'Tremoço',
    peanut: 'Amendoim',
    pearl_millet: 'Milheto',
    popcorn: 'Milho Pipoca',
    rye: 'Centeio',
    ryegrass: 'Azevém',
    soybean: 'Soja',
    sugarcane: 'Cana-de-açucar',
    sunflower: 'Girassol',
    triticale: 'Triticale',
    turnip: 'Nabo',
    upland_rice: 'Arroz de Sequeiro',
    velvet_bean: 'Mucuna',
    vetch: 'Ervilhaca',
    wheat: 'Trigo',
    white_oats: 'Aveia Branca',
    yucca: 'Mandioca',
  },
  control_method: {
    biochemical: 'Bioquímico',
    biological: 'Biológico',
    chemical: 'Químico',
  },
  pesticide_class: {
    biochemicals_with_multiple_modes_of_action:
      'Bioquímicos com Múltiplos Modos de Ação',
    biologicals_with_multiple_modes_of_action:
      'Biológicos com Múltiplos Modos de Ação',
    fungicide: 'Fungicida',
    herbicide: 'Herbicida',
    insecticide: 'Inseticida',
    nematicide: 'Nematicida',
    plant_extract: 'Extratos Vegetais',
    plant_resistance_activator: 'Ativador de Defesa em Plantas',
    plant_yield_activator: 'Ativador de Produtividade em Plantas',
    unknown: 'Desconhecido',
  },
  pesticide_mode_of_action: {
    contact_and_ingestion_insecticide: 'Inseticida de Contato e Ingestão',
    contact_insecticide: 'Inseticida de Contato',
    ingestion_insecticide: 'Inseticida de Ingestão',
    inorganic_multi_site_fungicide: 'Fungicida Multissítio Inorgânico',
    multi_site_fungicide: 'Fungicida Multissítio',
    organic_multi_site_fungicide: 'Fungicida Multissítio Orgânico',
    site_specific_fungicide: 'Fungicida Sítio-Específico',
    systemic_and_contact_insecticide: 'Inseticida Sistêmico e Contato',
    systemic_insecticide: 'Inseticida Sistêmico',
    systemic_nematicide: 'Nematicida Sistêmico',
    unknown: 'Desconhecido',
  },
  chemical_group: {
    acylalaninate: 'Acilalaninato',
    amino_pyrazolinones: 'Amino Pirazolinonas',
    anilinopyrimidine: 'Anilinopirimidina',
    avermectin: 'Avermectina',
    benzamide: 'Benzamida',
    benzimidazole: 'Benzimidazol',
    benzofuranyl_methylcarbamate: 'Metilcarbamato de Benzofuranila',
    benzothiodiazole: 'Benzotiodiazol',
    biochemical: 'Bioquímico',
    biological: 'Biológico',
    carboxamide: 'Carboxamida',
    cyanoacetamide_oxime: 'Cyanoacetamida Oxima',
    dicarboximide: 'Dicarboximida',
    dithiocarbamate: 'Ditiocarbamato',
    fluoroalkenyl: 'Fluoroalcenil',
    inorganic: 'Inorgânico',
    isophthalonitrile: 'Isoftalonitrila',
    morpholine: 'Morfolina',
    neonicotinoid: 'Neonicotinoide',
    organophosphate: 'Organofosforado',
    others: 'Outros',
    oxime_methylcarbamate: 'Metilcarbamato de Oxima',
    phenyl_oxo_ethyl_thiophene_amide: 'Phenyl Oxo Ethyl Thiophene Amide',
    phenylpyridinylamine: 'Fenilpiridinilamina',
    phenylpyrrole: 'Fenilpirrol',
    plant_extract: 'Extrato Vegetal',
    pyrimidine: 'Pirimidina',
    quaternary_ammonium: 'Amônio Quaternário',
    strobilurin: 'Estrobilurina',
    triazole: 'Triazol',
    triazolinthione: 'Triazolinthione',
  },
  active_ingredient: {
    abamectin: 'Abamectina',
    acibenzolar_s_methyl: 'Acibenzolar-S-Metil',
    allium_sativum: 'Allium Sativum',
    azoxystrobin: 'Azoxytstrobin',
    bacillus_amyloliquefaciens: 'Bacillus Amyloliquefaciens',
    bacillus_firmus: 'Bacillus Firmus',
    bacillus_licheniformis: 'Bacillus Licheniformis',
    bacillus_methylotrophicus: 'Bacillus Methylotrophicus',
    bacillus_paralicheniformis: 'Bacillus Paralicheniformis',
    bacillus_pumilus: 'Bacillus Pumilus',
    bacillus_subtilis: 'Bacillus Subtilis',
    bacillus_velezensis: 'Bacillus Velezensis',
    benzalkonium_chloride: 'Cloreto de Benzalcônio',
    benzovindiflupyr: 'Benzovindiflupyr',
    bixafen: 'Bixafen',
    boscalid: 'Boscalid',
    bromuconazole: 'Bromuconazole',
    cadusafos: 'Cadusafós',
    captan: 'Captan',
    carbendazim: 'Carbendazim',
    carbofuran: 'Carbofuran',
    carbosulfan: 'Carbosulfan',
    carboxin: 'Carboxin',
    cerevisane: 'Cerevisane',
    chlorothalonil: 'Clorotalonil',
    copper_hydroxide: 'Hidróxido de Cobre',
    copper_oxide: 'Óxido Cúprico',
    copper_oxychloride: 'Oxicloreto de Cobre',
    cyclobutrifluram: 'Ciclobutrifluram',
    cymoxanil: 'Cymoxanil',
    cyproconazole: 'Cyproconazole',
    cyprodinil: 'Cyprodinil',
    difenoconazole: 'Difenoconazole',
    dimoxystrobin: 'Dimoxystrobin',
    epoxiconazole: 'Epoxiconazole',
    fatty_acids: 'Ácidos Graxos',
    fenamifos: 'Fenamifós',
    fenarimol: 'Fenarimol',
    fenpropidin: 'Fenpropidin',
    fenpropimorph: 'Fenpropimorph',
    fenpyrazamine: 'Fenpyrazamine',
    fluazinam: 'Fluazinam',
    fludioxonil: 'Fludioxonil',
    fluensulfona: 'Fluensulfona',
    fluindapyr: 'Fluindapyr',
    fluopyram: 'Fluopyram',
    fluoxastrobin: 'Fluoxastrobin',
    fluquinconazole: 'Fluquinconazole',
    flutriafol: 'Flutriafol',
    fluxapyroxad: 'Fluxapyroxad',
    imidacloprid: 'Imidacloprido',
    inpyrfluxam: 'Inpyrfluxam',
    ipconazole: 'Ipconazole',
    iprodione: 'Iprodione',
    isofetamid: 'Isofetamid',
    kresoxim_methyl: 'Kresoxim-Methyl',
    mancozeb: 'Mancozeb',
    mefentrifluconazole: 'Mefentrifluconazole',
    melaleuca_alternifolia: 'Melaleuca Alternifolia',
    metalaxyl_m: 'Metalaxil-M',
    metconazole: 'Metconazole',
    metiram: 'Metiram',
    metominostrobin: 'Metominostrobin',
    mineral_extract: 'Extratos Minerais',
    myclobutanil: 'Myclobutanil',
    orange_vegetable_oil: 'Óleo Vegetal de Laranja',
    others: 'Outros',
    paecilomyces_lilacinus: 'Paecilomyces Lilacinus',
    pasteuria_nishizawae: 'Pasteuria Nishizawae',
    picoxystrobin: 'Picoxystrobin',
    plant_extract: 'Extratos de Plantas',
    pochonia_chlamydosporia: 'Pochonia Chlamydosporia',
    procymidone: 'Procymidone',
    propiconazole: 'Propiconazole',
    propineb: 'Propineb',
    prothioconazole: 'Prothioconazole',
    purpureocillium_lilacinum: 'Purpureocillium Lilacinum',
    pydiflumetofen: 'Pydiflumetofen',
    pyraclostrobin: 'Pyraclostrobin',
    sulfur: 'Enxofre',
    tebuconazole: 'Tebuconazole',
    terbufos: 'Terbufós',
    tetraconazole: 'Tetraconazole',
    thiabendazole: 'Thiabendazole',
    thiodicarb: 'Tiodicarbe',
    thiophanate_methyl: 'Tiophanato-Metila',
    thiram: 'Thiram',
    triadimefon: 'Triadimefon',
    trichoderma_afroharzianum: 'Trichoderma Afroharzianum',
    trichoderma_asperellum: 'Trichoderma Asperellum',
    trichoderma_endophyticum: 'Trichoderma Endophyticum',
    trichoderma_harzianum: 'Trichoderma Harzianum',
    tricyclazole: 'Tricyclazole',
    trifloxystrobin: 'Trifloxystrobin',
  },
  pesticide_formulation: {
    encapsulated_suspension_cs: 'Suspensão de Encapsulado (CS)',
    dispersible_concentrate_dc: 'Concentrado Dispersível (DC)',
    emulsifiable_concentrate_ec: 'Concentrado Emulsionável (EC)',
    water_in_oil_emulsion_eo: 'Emulsão de Água em Óleo (EO)',
    oil_in_water_emulsion_ew: 'Emulsão de Óleo em Água (EW)',
    microemulsion_me: 'Microemulsão (ME)',
    concentrated_suspension_sc: 'Suspensão Concentrada (SC)',
    suspo_emulsion_se: 'Suspo-Emulsão (SE)',
    soluble_granules_sg: 'Granulado Solúvel (SG)',
    soluble_concentrate_sl: 'Concentrado Solúvel (SL)',
    soluble_powder_sp: 'Pó Solúvel (SP)',
    tablet_tb: 'Tablete (TB)',
    tablet_for_direct_application_dt: 'Tablete para Aplicação Direta (DT)',
    tablet_for_dissolution_in_water_st: 'Tablete para Dissolução em Água (ST)',
    tablet_for_dispersion_in_water_wt: 'Tablete para Dispersão em Água (WT)',
    dispersible_granules_wg: 'Granulado Dispersível (WG)',
    wettable_powder_wp: 'Pó Molhável (WP)',
    block_br: 'Bloco (BR)',
    gelou_concentrate_paste_pc: 'Gelou Concentrado em Pasta (PC)',
    emulsifiable_gel_gl: 'Gel Emulsionável (GL)',
    water_soluble_gel_gw: 'Gel Solúvel em Água (GW)',
    emulsifiable_granule_eg: 'Grânulo Emulsionável (EG)',
    emulsifiable_powder_ep: 'Pó Emulsionável (EP)',
    oil_dispersion_or_oil_concentrated_suspension_od:
      'Dispersão de Óleo ou Suspensão Concentrada em Óleo (OD)',
    mixed_formulation_of_cs_and_sc_zc: 'Formulação Mista de CS e SC (ZC)',
    mixed_formulation_of_cs_and_se_ze: 'Formulação Mista de CS e SE (ZE)',
    mixed_formulation_of_cs_and_ew_zw: 'Formulação Mista de CS e EW (ZW)',
    concentrated_suspension_dispersible_or_miscible_in_oil_of:
      'Suspensão Concentrada Dispersível ou Miscível em Óleo (OF)',
    oil_miscible_solution_oi: 'Solução Miscível em Óleo (OI)',
    oil_dispersible_powder_op: 'Pó Dispersível em Óleo (OP)',
    encapsulated_granules_cg: 'Granulado Encapsulado (CG)',
    dry_powder_dp: 'Pó Seco (DP)',
    liquid_for_electrostatic_electrodynamic_spraying_ed:
      'Líquido para Pulverização Eletrostática/Eletrodinâmica (ED)',
    granules_gr: 'Granulado (GR)',
    oil_for_spraying_spreading_so: 'Óleo para Pulverização/Espalhamento (SO)',
    ultra_low_volume_suspension_su: 'Suspensão a Ultrabaixo Volume (SU)',
    ultra_low_volume_ul: 'Ultrabaixo Volume (UL)',
    microgranulate_mg: 'Microgranulado (MG)',
    fine_powder_gp: 'Pó Fino (GP)',
    fine_granulated_fg: 'Granulado Fino (FG)',
    macrogranulate_gg: 'Macrogranulado (GG)',
    contact_powder_cp: 'Pó de Contato (CP)',
    liquid_or_contact_gel_cl: 'Líquido ou Gel de Contato (CL)',
    concentrated_suspension_for_direct_application_sd:
      'Suspensão Concentrada para Aplicação Direta (SD)',
    other_liquids_for_direct_application_al:
      'Outros Líquidos para Aplicação Direta (AL)',
    other_post_ap: 'Outros Pós (AP)',
    dry_seed_treatment_powder_ds: 'Pó para Tratamento a Seco de Sementes (DS)',
    emulsion_for_seed_treatment_es: 'Emulsão para Tratamento de Sementes (ES)',
    concentrated_suspension_for_seed_treatment_fs:
      'Suspensão Concentrada para Tratamento de Sementes (FS)',
    solution_for_seed_treatment_ls: 'Solução para Tratamento de Sementes (LS)',
    soluble_powder_for_seed_treatment_ss:
      'Pó Solúvel para Tratamento de Sementes (SS)',
    powder_for_oil_paste_preparation_wo:
      'Pó para Preparação de Pasta em Óleo (WO)',
    powder_for_pasting_in_water_ws: 'Pó para Preparação de Pasta em Água (WS)',
    encapsulated_suspension_for_seed_treatment_cf:
      'Suspensão de Encapsulado para Tratamento de Semente (CF)',
    seed_treatment_gel_gf: 'Gel para Tratamento de Sementes (GF)',
    aerosol_ae: 'Aerosol (AE)',
    fumigant_fu: 'Fumigante (FU)',
    fumigant_tablet_fd: 'Pastilha Fumigante (FD)',
    fumigant_candle_fk: 'Vela Fumigante (FK)',
    fumigant_cartridge_fp: 'Cartucho Fumigante (FP)',
    fumigant_stick_fr: 'Bastonete Fumigante (FR)',
    fumigant_tablet_ft: 'Tablete Fumigante (FT)',
    fumigant_granules_fw: 'Granulado Fumigante (FW)',
    liquefied_gas_under_pressure_ga: 'Gás Liquefeito sob Pressão (GA)',
    gas_generator_ge: 'Gerador de Gás (GE)',
    concentrate_for_thermal_fogging_hn:
      'Concentrado para Termonebulização (HN)',
    concentrate_for_cold_fogging_kn: 'Concentrado para Nebulização a Frio (KN)',
    hairspray_la: 'Laca (LA)',
    vegetable_rod_pr: 'Bastonete Vegetal (PR)',
    folder_pa: 'Pasta (PA)',
    bait_rb: 'Isca (RB)',
    bait_in_grains_ab: 'Isca em Grãos (AB)',
    block_bait_bb: 'Isca em Blocos (BB)',
    granulated_bait_cg: 'Isca Granulada (CG)',
    plate_bait_pb: 'Isca em Placas (PB)',
    bait_in_shavings_5b: 'Isca em Aparas (5B)',
    steam_producer_vp: 'Produtor de Vapor (VP)',
    oily_paste_gs: 'Pasta Oleosa (GS)',
    formulated_bag_fb: 'Formulado ensacado (FB)',
    others_xx: 'Outros (XX)',
  },
  crop_cycle: {
    medium: 'Médio',
    early: 'Precoce',
    super_early: 'Superprecoce',
    late: 'Tardio',
    ultra_early: 'Hiperprecoce',
    long: 'Largo',
  },
  growth_habit: {
    determinate: 'Determinado',
    semi_determinate: 'Semideterminado',
    indeterminate: 'Indeterminado',
  },
  resistance_plant_reaction: {
    resistant: 'Resistente',
    moderately_resistant: 'Moderadamente Resistente',
    intermediate: 'Intermediário',
    moderately_susceptible: 'Moderadamente Suscetível',
    susceptible: 'Suscetível',
    no_information: 'Sem Informação',
  },
  product_unit_measure: {
    percentage: '%',
    bushel: 'bushel',
    l_100_kg_de_semente: 'L/100 kg de semente',
    l: 'L',
    lb: 'lb',
    kg_100_kg_de_semente: 'kg/100 kg de semente',
    kg: 'kg',
    g_kg_de_semente: 'g/kg de semente',
    g_ha: 'g/ha',
    ml_l: 'mL/L',
    mg_l: 'mg/L',
    fl_oz: 'fl oz',
    l_ha: 'L/ha',
    feet: 'feet',
    square_feet: 'square feet',
    l_50_kg_de_semente: 'L/50 kg de semente',
    ml_ha: 'mL/ha',
    g_l: 'g/L',
    g_kg: 'g/kg',
    oz: 'oz',
    gallon: 'gallon',
    ml_kg_de_semente: 'mL/kg de semente',
    ml_100_kg_de_semente: 'mL/100 kg de semente',
    kg_ha: 'kg/ha',
    g_100_kg_de_semente: 'g/100 kg de semente',
    miles_per_hour: 'miles per hour',
  },
}
