export default {
  phytosanitary_category: {
    diseases: 'Enfermedades',
    insects: 'Insectos',
    nematodes: 'Nematodos',
    weeds: 'Malas hierbas',
  },
  genetics_category: {
    variety: 'Cultivar',
    hybrids: 'Hibrido',
    varieties: 'Variedad',
  },
  plant_nutrition_category: {
    correction_k: 'Corrección de potasio',
    correction_p: 'Corrección de fósforo',
    correction_ph: 'corrección de pH',
    cover_crops: 'Fertilizante orgánico a través de cultivos de cobertura.',
    manutention_k: 'K Mantenimiento',
    manutention_n: 'N Mantenimiento',
    manutention_p: 'P Mantenimiento',
    organic_manure: 'Fertilización orgánica con estiércol.',
    plant_nutrition: 'Nutrición de plantas',
    resistance_activation: 'Activación de resistencia',
    sowing_k: 'Aplicación de K en Siembra',
    sowing_n: 'Aplicación de N en Siembra',
    sowing_p: 'Aplicación de P en Siembra',
    yield_activation: 'Activación de la productividad',
  },
  operation_type: {
    aerial_spray: 'Aplicación Aérea',
    correction_fertilization: 'Fertilización de Corrección',
    desiccation_pre_harvest: 'Desecación Previa a la Cosecha',
    early_emerging_post: 'Pos Emergente Temprana',
    foliar_fertilization: 'Fertilización Foliar',
    harvest: 'Cosecha',
    in_furrow: 'Aplicación en el Surco de Siembra',
    late_emerging_post: 'Pos Emergente Tardío',
    maintenance_fertilization: 'Fertilización de Mantenimiento',
    organic_fertilizer: 'Fertilizante Orgánico',
    pre_emerging: 'Preemergente',
    pre_emergent_overlay: 'Superposición Preemergente',
    regular_emerging_post: 'Pos Emergente Regular',
    seed_treatment: 'Tratamiento de Semillas',
    sequential_desiccation_1: 'Desecación Secuencial 1',
    sequential_desiccation_2: 'Desecación Secuencial 2',
    sowing: 'Siembra',
    spray_on_bare_ground: 'Aplicación sobre Suelo Desnudo',
  },
  crop: {
    fallow: 'Barbecho',
    others: 'Otras',
    alfalfa: 'Alfala',
    barley: 'Cebada',
    bean: 'Frijol',
    black_oats: 'Avena negra',
    brachiaria: 'Braquiaria',
    brachiaria_bizantha: ' Braquiaria-Bizantha',
    brachiaria_decumbens: 'Brachiaria-Decumbens',
    brachiaria_riziziensis: 'Brachiaria-Riziziensis',
    canola: 'Canola',
    corn: 'Maíz',
    cotton: 'Algodón',
    cowpea: 'Feijão-Caupí',
    crotalaria: 'Crotalaria',
    crotalaria_breviflora: 'Crotalaria breviflora',
    crotalaria_juncea: 'Crotalaria-júncea',
    crotalaria_ochroleuca: 'Crotalaria-ochroleuca',
    crotalaria_spectabilis: 'Crotalaria-spectabilis',
    dry_pea: 'Guisante seco',
    flax: 'Lino',
    forage_pea: 'Guisante forrajero',
    forage_sorghum: 'Sorgo para ensilaje',
    grain_sorghum: 'Sorgo en grano',
    irrigated_rice: 'Arroz irrigado',
    lupin: 'Lupino',
    peanut: 'Maní',
    pearl_millet: 'Mijo',
    popcorn: 'Palomita',
    rye: 'Centeno',
    ryegrass: 'Ryegrass',
    soybean: 'Soja',
    sugarcane: 'Caña de azúcar',
    sunflower: 'Girasol',
    triticale: 'Triticale',
    turnip: 'Nabo',
    upland_rice: 'Arroz de tierras altas',
    velvet_bean: 'Mucuna',
    vetch: 'Arveja',
    wheat: 'Trigo',
    white_oats: 'Avena blanca',
    yucca: 'Yuca',
  },
  control_method: {
    biochemical: 'Bioquimico',
    biological: 'Biologico',
    chemical: 'Quimico',
  },
  pesticide_class: {
    biochemicals_with_multiple_modes_of_action:
      'Bioquímicos con Múltiples Modos de Acción',
    biologicals_with_multiple_modes_of_action:
      'Biológicos con Múltiples Modos de Acción',
    fungicide: 'Fungicida',
    herbicide: 'Herbicida',
    insecticide: 'Insecticida',
    nematicide: 'Nematicida',
    plant_extract: 'Extractos de Plantas',
    plant_resistance_activator: 'Activador de Defensa en Planta',
    plant_yield_activator: 'Activador de Productividad en Planta',
    unknown: 'Desconocido',
  },
  pesticide_mode_of_action: {
    contact_and_ingestion_insecticide: 'Insecticidas de Contacto e Ingestión',
    contact_insecticide: 'Insecticidas de Contacto',
    ingestion_insecticide: 'Insecticidas de Ingestión',
    inorganic_multi_site_fungicide: 'Fungicida Multisitio Inorgánico',
    multi_site_fungicide: 'Fungicida Multisitio',
    organic_multi_site_fungicide: 'Fungicida Multisitio Orgánico',
    site_specific_fungicide: 'Fungicida Sitio-Específico',
    systemic_and_contact_insecticide: 'Insecticida Sistémico y Contacto',
    systemic_insecticide: 'Insecticida Sistémico',
    systemic_nematicide: 'Nematicida Sistémico',
    unknown: 'Desconocido',
  },
  chemical_group: {
    acylalaninate: 'Acilalaninato',
    amino_pyrazolinones: 'Amino Pirazolinonas',
    anilinopyrimidine: 'Anilinopirimidina',
    avermectin: 'Avermectina',
    benzamide: 'Benzamida',
    benzimidazole: 'Benzimidazol',
    benzofuranyl_methylcarbamate: 'Benzofuranil Metilcarbamato',
    benzothiodiazole: 'Benzotiodiazol',
    biochemical: 'Bioquímico',
    biological: 'Biológico',
    carboxamide: 'Carboxamida',
    cyanoacetamide_oxime: 'Cyanoacetamida Oxima',
    dicarboximide: 'Dicarboximida',
    dithiocarbamate: 'Ditiocarbamato',
    fluoroalkenyl: 'Fluoroalcenil',
    inorganic: 'Inorgânico',
    isophthalonitrile: 'Isoftalonitrila',
    morpholine: 'Morfolina',
    neonicotinoid: 'Neonicotinoide',
    organophosphate: 'Organofosforado',
    others: 'Outros',
    oxime_methylcarbamate: 'Metilcarbamato de Oxima',
    phenyl_oxo_ethyl_thiophene_amide: 'Phenyl Oxo Ethyl Thiophene Amide',
    phenylpyridinylamine: 'Fenilpiridinilamina',
    phenylpyrrole: 'Fenilpirrol',
    plant_extract: 'Extrato Vegetal',
    pyrimidine: 'Pirimidina',
    quaternary_ammonium: 'Amônio Quaternário',
    strobilurin: 'Estrobilurina',
    triazole: 'Triazol',
    triazolinthione: 'Triazolinthione',
  },
  active_ingredient: {
    abamectin: 'Abamectina',
    acibenzolar_s_methyl: 'Acibenzolar-S-Metil',
    allium_sativum: 'Allium Sativum',
    azoxystrobin: 'Azoxytstrobin',
    bacillus_amyloliquefaciens: 'Bacillus Amyloliquefaciens',
    bacillus_firmus: 'Bacillus Firmus',
    bacillus_licheniformis: 'Bacillus Licheniformis',
    bacillus_methylotrophicus: 'Bacillus Methylotrophicus',
    bacillus_paralicheniformis: 'Bacillus Paralicheniformis',
    bacillus_pumilus: 'Bacillus Pumilus',
    bacillus_subtilis: 'Bacillus Subtilis',
    bacillus_velezensis: 'Bacillus Velezensis',
    benzalkonium_chloride: 'Cloreto de Benzalcônio',
    benzovindiflupyr: 'Benzovindiflupyr',
    bixafen: 'Bixafen',
    boscalid: 'Boscalid',
    bromuconazole: 'Bromuconazole',
    cadusafos: 'Cadusafós',
    captan: 'Captan',
    carbendazim: 'Carbendazim',
    carbofuran: 'Carbofuran',
    carbosulfan: 'Carbosulfan',
    carboxin: 'Carboxin',
    cerevisane: 'Cerevisane',
    chlorothalonil: 'Clorotalonil',
    copper_hydroxide: 'Hidróxido de Cobre',
    copper_oxide: 'Óxido Cúprico',
    copper_oxychloride: 'Oxicloreto de Cobre',
    cyclobutrifluram: 'Ciclobutrifluram',
    cymoxanil: 'Cymoxanil',
    cyproconazole: 'Cyproconazole',
    cyprodinil: 'Cyprodinil',
    difenoconazole: 'Difenoconazole',
    dimoxystrobin: 'Dimoxystrobin',
    epoxiconazole: 'Epoxiconazole',
    fatty_acids: 'Ácidos Graxos',
    fenamifos: 'Fenamifós',
    fenarimol: 'Fenarimol',
    fenpropidin: 'Fenpropidin',
    fenpropimorph: 'Fenpropimorph',
    fenpyrazamine: 'Fenpyrazamine',
    fluazinam: 'Fluazinam',
    fludioxonil: 'Fludioxonil',
    fluensulfona: 'Fluensulfona',
    fluindapyr: 'Fluindapyr',
    fluopyram: 'Fluopyram',
    fluoxastrobin: 'Fluoxastrobin',
    fluquinconazole: 'Fluquinconazole',
    flutriafol: 'Flutriafol',
    fluxapyroxad: 'Fluxapyroxad',
    imidacloprid: 'Imidacloprido',
    inpyrfluxam: 'Inpyrfluxam',
    ipconazole: 'Ipconazole',
    iprodione: 'Iprodione',
    isofetamid: 'Isofetamid',
    kresoxim_methyl: 'Kresoxim-Methyl',
    mancozeb: 'Mancozeb',
    mefentrifluconazole: 'Mefentrifluconazole',
    melaleuca_alternifolia: 'Melaleuca Alternifolia',
    metalaxyl_m: 'Metalaxil-M',
    metconazole: 'Metconazole',
    metiram: 'Metiram',
    metominostrobin: 'Metominostrobin',
    mineral_extract: 'Extrato Mineral',
    myclobutanil: 'Myclobutanil',
    orange_vegetable_oil: 'Óleo Vegetal de Laranja',
    others: 'Outros',
    paecilomyces_lilacinus: 'Paecilomyces Lilacinus',
    pasteuria_nishizawae: 'Pasteuria Nishizawae',
    picoxystrobin: 'Picoxystrobin',
    plant_extract: 'Extrato de Plantas',
    pochonia_chlamydosporia: 'Pochonia Chlamydosporia',
    procymidone: 'Procymidone',
    propiconazole: 'Propiconazole',
    propineb: 'Propineb',
    prothioconazole: 'Prothioconazole',
    purpureocillium_lilacinum: 'Purpureocillium Lilacinum',
    pydiflumetofen: 'Pydiflumetofen',
    pyraclostrobin: 'Pyraclostrobin',
    sulfur: 'Enxofre',
    tebuconazole: 'Tebuconazole',
    terbufos: 'Terbufós',
    tetraconazole: 'Tetraconazole',
    thiabendazole: 'Thiabendazole',
    thiodicarb: 'Tiodicarbe',
    thiophanate_methyl: 'Tiopanato-Metila',
    thiram: 'Thiram',
    triadimefon: 'Triadimefon',
    trichoderma_afroharzianum: 'Trichoderma Afroharzianum',
    trichoderma_asperellum: 'Trichoderma Asperellum',
    trichoderma_endophyticum: 'Trichoderma Endophyticum',
    trichoderma_harzianum: 'Trichoderma Harzianum',
    tricyclazole: 'Tricyclazole',
    trifloxystrobin: 'Trifloxystrobin',
  },
  pesticide_formulation: {
    encapsulated_suspension_cs: 'Suspensión Encapsulada (CS)',
    dispersible_concentrate_dc: 'Concentrado dispersable (DC)',
    emulsifiable_concentrate_ec: 'Concentrado Emulsionable (EC)',
    water_in_oil_emulsion_eo: 'Emulsión de agua en aceite (EO)',
    oil_in_water_emulsion_ew: 'Emulsión de aceite en agua (EW)',
    microemulsion_me: 'Microemulsión (ME)',
    concentrated_suspension_sc: 'Suspensión concentrada (SC)',
    suspo_emulsion_se: 'Suspo-Emulsión (SE)',
    soluble_granules_sg: 'Gránulos Solubles (SG)',
    soluble_concentrate_sl: 'Concentrado Soluble (SL)',
    soluble_powder_sp: 'Polvo Soluble (SP)',
    tablet_tb: 'Tableta (TB)',
    tablet_for_direct_application_dt: 'Tableta para Aplicación Directa (DT)',
    tablet_for_dissolution_in_water_st: 'Tableta para Disolución en Agua (ST)',
    tablet_for_dispersion_in_water_wt: 'Tableta para Dispersión en Agua (WT)',
    dispersible_granules_wg: 'Gránulos dispersables (WG)',
    wettable_powder_wp: 'Polvo mojable (WP)',
    block_br: 'Bloque (BR)',
    gelou_concentrate_paste_pc: 'Pasta concentrada Gelou (PC)',
    emulsifiable_gel_gl: 'Gel Emulsionable (GL)',
    water_soluble_gel_gw: 'Gel soluble en agua (GW)',
    emulsifiable_granule_eg: 'Gránulo emulsionable (EG)',
    emulsifiable_powder_ep: 'Polvo Emulsionable (EP)',
    oil_dispersion_or_oil_concentrated_suspension_od:
      'Dispersión de aceite o suspensión concentrada de aceite (OD)',
    mixed_formulation_of_cs_and_sc_zc: 'Formulación Mixta de CS y SC (ZC)',
    mixed_formulation_of_cs_and_se_ze: 'Formulación Mixta de CS y SE (ZE)',
    mixed_formulation_of_cs_and_ew_zw: 'Formulación Mixta de CS y EW (ZW)',
    concentrated_suspension_dispersible_or_miscible_in_oil_of:
      'Suspensión concentrada dispersable o miscible en aceite (OF)',
    oil_miscible_solution_oi: 'Solución Miscible en Aceite (OI)',
    oil_dispersible_powder_op: 'Polvo dispersable en aceite (OP)',
    encapsulated_granules_cg: 'Gránulos Encapsulados (CG)',
    dry_powder_dp: 'Polvo seco (DP)',
    liquid_for_electrostatic_electrodynamic_spraying_ed:
      'Líquido para pulverización electrostática/electrodinámica (ED)',
    granules_gr: 'Gránulos (GR)',
    oil_for_spraying_spreading_so: 'Aceite para Pulverizar/Esparcir (SO)',
    ultra_low_volume_suspension_su: 'Suspensión de volumen ultrabajo (SU)',
    ultra_low_volume_ul: 'Volumen ultra bajo (UL)',
    microgranulate_mg: 'Microgranulado (MG)',
    fine_powder_gp: 'Polvo fino (GP)',
    fine_granulated_fg: 'Granulado Fino (FG)',
    macrogranulate_gg: 'Macrogranulado (GG)',
    contact_powder_cp: 'Polvo de contacto (CP)',
    liquid_or_contact_gel_cl: 'Líquido o Gel de Contacto (CL)',
    concentrated_suspension_for_direct_application_sd:
      'Suspensión Concentrada de Aplicación Directa (SD)',
    other_liquids_for_direct_application_al:
      'Otros Líquidos de Aplicación Directa (AL)',
    other_post_ap: 'Otra publicación (AP)',
    dry_seed_treatment_powder_ds: 'Polvo de tratamiento de semillas secas (DS)',
    emulsion_for_seed_treatment_es:
      'Emulsión para Tratamiento de Semillas (ES)',
    concentrated_suspension_for_seed_treatment_fs:
      'Suspensión Concentrada para Tratamiento de Semillas (FS)',
    solution_for_seed_treatment_ls:
      'Solución para Tratamiento de Semillas (LS)',
    soluble_powder_for_seed_treatment_ss:
      'Polvo Soluble para Tratamiento de Semillas (SS)',
    powder_for_oil_paste_preparation_wo:
      'Polvo para Preparación de Pasta de Aceite (WO)',
    powder_for_pasting_in_water_ws: 'Polvo para Pegar en Agua (WS)',
    encapsulated_suspension_for_seed_treatment_cf:
      'Suspensión Encapsulada para Tratamiento de Semillas (CF)',
    seed_treatment_gel_gf: 'Gel de tratamiento de semillas (GF)',
    aerosol_ae: 'Aerosoles (AE)',
    fumigant_fu: 'Fumigante (FU)',
    fumigant_tablet_fd: 'Tableta fumigante (FD)',
    fumigant_candle_fk: 'Vela Fumigante (FK)',
    fumigant_cartridge_fp: 'Cartucho fumigante (FP)',
    fumigant_stick_fr: 'Barra fumigante (FR)',
    fumigant_tablet_ft: 'Tableta Fumigante (FT)',
    fumigant_granules_fw: 'Gránulos fumigantes (FW)',
    liquefied_gas_under_pressure_ga: 'Gas Licuado Bajo Presión (GA)',
    gas_generator_ge: 'Generador de gas (GE)',
    concentrate_for_thermal_fogging_hn:
      'Concentrado para Nebulización Térmica (HN)',
    concentrate_for_cold_fogging_kn:
      'Concentrado para nebulización en frío (KN)',
    hairspray_la: 'Laca para el cabello (LA)',
    vegetable_rod_pr: 'Varilla Vegetal (PR)',
    folder_pa: 'Carpeta (PA)',
    bait_rb: 'Cebo (RB)',
    bait_in_grains_ab: 'Cebo en Granos (AB)',
    block_bait_bb: 'Cebo de bloque (BB)',
    granulated_bait_cg: 'Cebo Granulado (CG)',
    plate_bait_pb: 'Cebo de placa (PB)',
    bait_in_shavings_5b: 'Cebo en Virutas (5B)',
    steam_producer_vp: 'Productor de vapor (VP)',
    oily_paste_gs: 'Pasta oleosa (GS)',
    formulated_bag_fb: 'Formulada en bolsa (FB)',
    others_xx: 'Otros (XX)',
  },
  crop_cycle: {
    medium: 'Medio',
    early: 'Temprano',
    super_early: 'Súper Temprano',
    late: 'Tardío',
    ultra_early: 'Hiperprecoce',
    long: 'Largo',
  },
  growth_habit: {
    determinate: 'Determinado',
    semi_determinate: 'Semideterminado',
    indeterminate: 'Indeterminado',
  },
  resistance_plant_reaction: {
    resistant: 'Resistente',
    moderately_resistant: 'Moderadamente Resistente',
    intermediate: 'Intermediario',
    moderately_susceptible: 'Moderadamente Susceptible',
    susceptible: 'Susceptible',
    no_information: 'Sin Información',
  },
  product_unit_measure: {
    percentage: '%',
    bushel: 'bushel',
    l_100_kg_de_semente: 'L/100 kg de semente',
    l: 'L',
    lb: 'lb',
    kg_100_kg_de_semente: 'kg/100 kg de semente',
    kg: 'kg',
    g_kg_de_semente: 'g/kg de semente',
    g_ha: 'g/ha',
    ml_l: 'mL/L',
    mg_l: 'mg/L',
    fl_oz: 'fl oz',
    l_ha: 'L/ha',
    feet: 'feet',
    square_feet: 'square feet',
    l_50_kg_de_semente: 'L/50 kg de semente',
    ml_ha: 'mL/ha',
    g_l: 'g/L',
    g_kg: 'g/kg',
    oz: 'oz',
    gallon: 'gallon',
    ml_kg_de_semente: 'mL/kg de semente',
    ml_100_kg_de_semente: 'mL/100 kg de semente',
    kg_ha: 'kg/ha',
    g_100_kg_de_semente: 'g/100 kg de semente',
    miles_per_hour: 'miles per hour',
  },
}
